export default function usuario() {
    const nome_user = $("#corpo > div > div.secao-principal.row-fluid.sem-coluna > div.coluna.span3 > div > span")

    nome_user.html(`<p>Bem vindo (a)</p>` + nome_user.html())

    $("#corpo > div > div.secao-principal.row-fluid.sem-coluna > div.conteudo.span9 > div > div > div:nth-child(1) > h3").addClass("titulo-minha-conta")

    const link_outros_enderecos = $("#corpo > div > div.secao-principal.row-fluid.sem-coluna > div.conteudo.span9 > div > div > div.caixa-dados.outros-enderecos > div > div > fieldset > legend > a");

    const outros_enderecos = $("#corpo > div > div.secao-principal.row-fluid.sem-coluna > div.conteudo.span9 > div > div > div.caixa-dados.outros-enderecos > div > div > fieldset");

    link_outros_enderecos.appendTo(outros_enderecos);

    $("#corpo > div > div.secao-principal.row-fluid.sem-coluna > div.conteudo.span9 > div > div > div.caixa-dados.outros-enderecos > h3").remove()


}