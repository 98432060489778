export default function listagemprodutos() {
    for (const icon of $(".icon-search")) {
        icon.remove(".icon")
    }
    for (const btn of $(".acoes-produto > a")) {
        btn.innerText = "Adicionar ao carrinho"
    }

    for (const text of $("div.acoes-produto-responsiva.visible-phone > a")) {
        text.remove("div.acoes-produto-responsiva.visible-phone > a")
    }

    $("#corpo > div > div.secao-principal.row-fluid > div.conteudo.span9 > h1").remove("h1")

    $("body > div.conteiner-principal > div.secao-banners").remove("div.secao-banners")
    $("#corpo > div > div.secao-principal.row-fluid > div.conteudo.span9 > div.ordenar-listagem.topo.borda-alpha > div > div").css("width", "100%")
    $("#corpo > div > div.secao-principal.row-fluid > div.conteudo.span9 > div.ordenar-listagem.topo.borda-alpha > div > div > div").css("float", "right")
    $("div.ordenar-listagem.topo.borda-alpha>div>div>div>div>div>button").html('<?xml version="1.0"  encoding="iso-8859-1"?><svg fill="#000000" height="20px" width="20px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve"><path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"/></svg> ')

    $(".icon-envelope-alt").remove(".icon-envelope-alt")

}

