export default function paginapadrao() {
    const conteudo = $("#corpo > div > div.secao-principal.row-fluid.sem-coluna > div ")
    conteudo.html(conteudo[0].innerHTML.replace("undefined", " "))
    console.log("teste", $("#corpo > div > div.secao-principal.row-fluid.sem-coluna > div "))

    $("body > div.conteiner-principal > div.secao-banners").remove("div.secao-banners")

    $("#corpo > div > div.secao-principal.row-fluid.sem-coluna > div").css({
        'background-color': 'white',
        'border-radius': '20px'
    });

    $('#corpo > div > div.secao-principal.row-fluid.sem-coluna > div > div').css({
        'box-shadow': 'none',
        'background-color': 'white',
        'margin': '9vw',
        'border': '1px solid #e7e7e7',
        'padding': '7vw',
        'color': 'black'
    });

    $('#corpo > div > div.breadcrumbs.borda-alpha').css({
        'border': '0'
    });
}
