import navBar from "./home/navbar/navbar.js";
import geral from "./home/geral/geral.js";
import banner from "./home/banner/banner.js";
import minibanner from "./home/minibanner/minibanner.js";
import listagemprodutos from "./home/listagemprodutos/listagemprodutos.js";
import footer from "./home/footer/footer.js";
import paginapadrao from "./home/pagina-padrao/meios-de-pagamento-e-de-frete.js";
import paginaproduto from "./paginaproduto/paginaproduto.js"
import checkout from "./checkout/checkout.js"
import usuario from "./usuario/usuario.js"

import "./home/navbar/navbar.scss";
import "./home/geral/geral.scss";
import "./home/banner/banner.scss";
import "./home/footer/footer.scss";
import "./home/minibanner/minibanner.scss";
import "./home/listagemprodutos/listagemprodutos.scss";
import "./base64/base64.scss";
import "./avancado.scss"
import "./paginaproduto/paginaproduto.scss"
import "./checkout/checkout.scss"
import "./usuario/usuario.scss"




(() => {
    document.addEventListener("DOMContentLoaded", function () {
        navBar()
        geral()

        if (!window.location.pathname.match(/checkout/) && !window.location.pathname.match(/conta/) && !window.location.pathname.match(/meios-de-pagamento-e-de-frete/)) {
            paginaproduto()
            banner()
            minibanner()
            listagemprodutos()
        }

        if (window.location.pathname.match(/meios-de-pagamento-e-de-frete/)) {
            paginapadrao()
        }

        footer()
        checkout()
        usuario()
    })
})()
