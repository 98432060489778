export default function paginaproduto() {
    $("#cabecalho > div.conteiner.hidden-phone > div.menu.superior").css("border-bottom", "")

    const row_first = $(".conteiner-principal > #corpo > .conteiner > .secao-principal > .produto")
    if(row_first) {row_first[0]?.children[0]?.classList.add("row_first")}

    const info_prod = $("#corpo > div > div.secao-principal.row-fluid.sem-coluna > div > div.info-principal-produto")

    $("#corpo > div > div.secao-principal.row-fluid.sem-coluna > div > div:nth-child(2) > div.span5 > div").prepend(info_prod);


    const forma_pagamento_btn = $(".accordion > li:nth-child(1) > div.accordion-heading > a > span")
    if (forma_pagamento_btn) forma_pagamento_btn.addClass("ver_parcelas")
    $(".accordion > li:nth-child(1) > div.accordion-heading > a > span > span").remove()
    
    $("#corpo > div > div.secao-principal.row-fluid.sem-coluna > div > div.row-fluid.row_first > div.span7 > div.thumbs-vertical.hidden-phone").remove()

    $(".accordion > li:nth-child(1) > div.accordion-heading > a > img").remove()
    $(".accordion > li:nth-child(2) > div > span > img").remove()
    $("#formCalcularCep > div > div > button").html("Calcular")
    
    const desc_entrega = $("#corpo > div > div.secao-principal.row-fluid.sem-coluna > div > div.row-fluid.row_first > div.span5 > div > div.cep > div")

    if(desc_entrega) {
      if(desc_entrega[0]) {
        desc_entrega[0].insertAdjacentHTML("afterend", `<p class="desc_entrega"><strong>Os prazos anunciados acima são previsões aproximadas</strong> de acordo com informações repassadas pela empresa transportadora, podendo variar para mais ou para menos.</p>`)
      }
    }

    $("#carouselImagem").removeClass("visible-phone")

    const abaixo_btn_comprar = $("#corpo > div > div.secao-principal.row-fluid.sem-coluna > div > div.row-fluid.row_first > div.span5 > div > div.acoes-produto.disponivel > div.comprar > span")
    
    if(abaixo_btn_comprar) {
      if(abaixo_btn_comprar[0]) {
        abaixo_btn_comprar[0].insertAdjacentHTML("afterend", `<article class="compra-segura"><p>Compra 100% segura.</p><p>Pagamentos auditados pelo <b>Mercado Pago<b></p></article>`)
      }
    }

    return

    const desc_prod_padrao = $("#descricao > pre")
    const desc_prod = desc_prod_padrao.html()
    // desc_prod_padrao.remove()

    const divResultado = $('<div></div>');
    
    const linhas = desc_prod.split("\n")
    // Quebrar o texto em linhas
  
    let dentroLista = false;
    let listaUl;
  
    // Percorrer cada linha do texto
    linhas.forEach(linha => {
      // Remover espaços em branco do início e do final da linha
      linha = linha.trim();
  
      if (linha.startsWith('-')) {
        // Se a linha começar com "-", criar uma lista se ainda não existir
        if (!dentroLista) {
          dentroLista = true;
          listaUl = $('<ul></ul>');
          divResultado.append(listaUl);
        }
        // Criar um item de lista e adicionar à lista
        const itemLista = $('<li></li>').text(linha.substring(1).trim());
        listaUl.append(itemLista);
      } else {
        // Se não começar com "-", adicionar como parágrafo ou cabeçalho dependendo do caso
        const elemento = linha.toUpperCase() === linha ? 'h3' : 'p';
        const tagHtml = $('<' + elemento + '></' + elemento + '>').text(linha);
        divResultado.append(tagHtml);
        // Se estiver dentro de uma lista, sair da lista
        if (dentroLista) {
          dentroLista = false;
        }
      }
    });
  
    // Adicionar o resultado à página
    // $("#descricao").prepend(divResultado);
}