export default function banner() {
    if (window.location.pathname != "/") {
        return
    }


    let counter = 0
    let slides = $("#corpo > .conteiner > .secao-principal > .conteudo > .row-fluid > .span12 > .flexslider > .slides").children()
    // for (const slide of slides) {
    //     const parent = slide.parentElement
    //     if (counter != 2) {
    //         parent.removeChild(slide)
    //         counter++;
    //     }
    // }

    const rowFluid = $("#corpo > .conteiner > .secao-principal > .conteudo > .banner ")
    $("#corpo > .conteiner > .secao-principal > .conteudo > .banner ").remove(".banner")
    $('<div class="secao-banners-2"><div class="container"></div></div>').appendTo('.conteiner-principal');
    $('.secao-banners-2 > .container').append(rowFluid)


    $(".conteiner-principal > #cabecalho").after($('.secao-banners-2'))


    slides = $(".secao-banners-2 > .container > .row-fluid > .span12 > .flexslider > .slides")?.children()[0]

    if(slides) slides.children[0].src = "./src/images/home/banner/img_banner1.png"


    $(".secao-banners-2 > .container > .banner > .span12 > .flexslider > .flex-control-nav").remove(".flex-control-nav")
    $(".secao-banners-2 > .container > .banner").removeClass("vitrine hidden-phone")



}

